import styled, { css } from 'styled-components'

import { BannerSubscriptionProps } from './BannerSubscription'

const BORDER_SIZE = 5

const GRADIENT = css`
  content: '';
  z-index: 1;
  position: absolute;
  left: 0;
  background-size: 100% 100%;
  width: 100%;
  height: ${BORDER_SIZE}px;
`

export const BannerTitle = styled.h1`
  font-size: 28px;
  text-align: center;
  margin: 0 0 10px 0;

  ${
    null
    // MQFullHd
  } {
    font-size: 40px;
  }
`

export const BannerDescription = styled.span`
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
  font-size: 17px;
  line-height: 1.26;
  color: ${({ theme }) => theme.secondaryColor};

  ${
    null
    // MQFullHd
  } {
    font-size: 18px;
  }
`

export const GiftButton = styled.button``

export const BannerContainer = styled.div<Pick<BannerSubscriptionProps, 'background'>>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 60px 20px;

  ${({ background }) =>
    background &&
    css`
      background-color: ${background};
    `}
`

export const BannerImage = styled.div.attrs({ as: 'section' })<
  Pick<BannerSubscriptionProps, 'images' | 'background'>
>`
  ${({ images, background }) =>
    !!images &&
    css`
      width: 100%;

      ${
        null
        // MQFullHd
      } {
        display: flex;
        flex-direction: row;

        &:before {
          content: '';
          position: absolute;
          top: ${BORDER_SIZE}px;
          left: 0;
          bottom: ${BORDER_SIZE}px;
          background-size: cover;
          width: 500px;
        }

        &:after {
          content: '';
          position: absolute;
          top: ${BORDER_SIZE}px;
          right: 0;
          bottom: ${BORDER_SIZE}px;
          background-size: cover;
          width: 500px;
        }
      }
    `}
`

export const TextWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1;
`

export const BorderGradient = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:before {
    ${GRADIENT}
    top: 0;
    background-image: linear-gradient(
      45deg,
      ${({ theme }) => `${theme.primaryForegroundColor}, ${theme.lightBlue}`}
    );
  }

  &:after {
    ${GRADIENT}
    bottom: 0;
    background-image: linear-gradient(
      45deg,
      ${({ theme }) => `${theme.lightBlue}, ${theme.primaryForegroundColor}`}
    );
  }
`

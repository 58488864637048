export const ABOUT_DEFAULT_IMAGE = 'https://images.majelan.audio/divers/v2/header_about.png'

export const FONDATEURS_DEFAULT_IMAGE = 'https://images.majelan.audio/divers/v2/fondateurs.png'

export const IOS_DEFAULT_IMAGE = 'https://images.majelan.audio/divers/v2/mobile_ios.png'

export const ANDROID_DEFAULT_IMAGE = 'https://images.majelan.audio/divers/v2/mobile_android.png'

export const DOWNLOAD_DEFAULT_IMAGE =
  'https://images.majelan.audio/divers/v2/background_download.png'

export const STUDIO_DEFAULT_IMAGE = 'https://images.majelan.audio/divers/v2/studio.png'

export const FIND_PLACE_DEFAULT_IMAGE = 'https://images.majelan.audio/divers/v2/find_place.png'

export const HERO_DEFAULT_IMAGE = 'https://images.majelan.audio/divers/v2/MAJELAN_Homepage.png'

export const HERO_DEFAULT_VIDEO =
  'https://images.majelan.audio/divers/v2/video/MAJELAN_FR_30SEC_VDEF3.mp4'

export const HERO_DEFAULT_VIDEO_PORTRAIT =
  'https://images.majelan.audio/divers/v2/video/MAJELAN_FR_VERTICAL_30s_VDEF.mp4'

export const HERO_DEFAULT_MOBILE = 'https://images.majelan.audio/divers/v2/header_mobile.png'

export const INPIRING_VOICES_DEFAULT_IMAGE =
  'https://images.majelan.audio/divers/v2/sections/section_des_voix_inspirantes.png'

export const ENRICHED_MOMENT_DEFAULT_IMAGE =
  'https://images.majelan.audio/divers/v2/sections/section_moment_enrichi.png'

export const PRESS_DEFAULT_IMAGE = 'https://images.majelan.audio/divers/v2/page_press.png'

export const EVOLVE_COVERS_DEFAULT_IMAGE =
  'https://images.majelan.audio/divers/v2/sections/evolve/covers.png'

export const EVOLVE_COVERS_MOBILE_IMAGE = 'https://images.majelan.audio/divers/v2/cover-mobile.png'

export const EVOLVE_ONDE_DEFAULT_IMAGE =
  'https://images.majelan.audio/divers/v2/sections/evolve/ondeclean.png'

export const EVOLVE_PROGRESS_DEFAULT_IMAGE =
  'https://images.majelan.audio/divers/v2/sections/evolve/progress2.png'

export const MAGIC_AUDIO_DEFAULT_IMAGE =
  'https://images.majelan.audio/divers/v2/sections/section_la_magie_de_l_audio_hand2.png'

export const NEW_LEARNING_DEFAULT_IMAGE =
  'https://images.majelan.audio/divers/v2/sections/section_3.png'

export const IPHONE_CATEGORIES_DEFAULT_IMAGE =
  'https://images.majelan.audio/divers/v2/sections/iphone-categories.png'

export const PERSONALITIES_DEFAULT_IMAGE =
  'https://images.majelan.audio/divers/v2/sections/section_voix_inspirantes.png'

export const PROGRESS_DEFAULT_IMAGE = 'https://images.majelan.audio/divers/v2/sections/episodes.png'

export const HAND_PHONE_DEFAULT_IMAGE = 'https://images.majelan.audio/divers/v2/sections/iphone.png'

export const IAP_MODAL_DEFAULT_IMAGE = 'https://images.majelan.audio/divers/modalPayment@3x.png'

export const DEFAULT_COUPON_IMAGE =
  'https://images.majelan.audio/divers/v2/gift/products/carte%20mensuelle.jpg'

export const DOWNLOAD_KIT_PRESS = 'https://files.majelan.audio/divers/v2/majelan_kit_presse.zip'

export const BANNER_SUBSCRIPTION_GIFT_CARD_LEFT =
  'https://images.majelan.audio/divers/v2/gift/homepage_banner-left.png'

export const BANNER_SUBSCRIPTION_GIFT_CARD_RIGHT =
  'https://images.majelan.audio/divers/v2/gift/homepage_banner-right.png'

export const BANNER_SUBSCRIPTION_GIFT_CARD_MOBILE =
  'https://images.majelan.audio/divers/v2/gift/landing-banner-mobile.png'

export const BANNER_SUBSCRIPTION_LEFT =
  'https://images.majelan.audio/divers/v2/home_banner_left.png'

export const BANNER_SUBSCRIPTION_RIGHT =
  'https://images.majelan.audio/divers/v2/home_banner_right.png'

export const BANNER_SUBSCRIPTION_MOBILE =
  'https://images.majelan.audio/divers/v2/home_banner_mobile.png'

export const SHARE_IMAGE = 'https://images.majelan.audio/divers/v2/MAJELAN_HOME.png'

export const GIFT_BANNER_DEFAULT_IMAGE = 'https://images.majelan.audio/divers/v2/gift/headerx3.png'

export const OFFERS_BANNER_DEFAULT_IMAGE =
  'https://images.majelan.audio/divers/v2/header_offre_juin21.png'

export const NEW_HERO_BANNER = 'https://images.majelan.audio/divers/v2/header_home_juin21.png'

export const NEW_HERO_BANNER_MOBILE =
  'https://images.majelan.audio/divers/v2/header_homemobile_juin21.png'

export const DEFAULT_CURRENCY = 'EUR'
export const ALLOWED_CONTRIES = ['FR']

export const COVERS_RESPONSIVE_SIZES =
  '(min-width: 1440px) calc(70vw / 4), (min-width: 375px) calc(100vw / 2), 20vw'

export const SHARED_SECTION_RESPONSIVE_SIZES =
  '(min-width: 1440px) calc(100vw / 4), (min-width: 375px) 90vw, 30vw'

export const PROGRAMS_TRAILERS = [
  {
    UUID: '11a6e219-3f79-498b-ab18-d694638d0d28',
    trailer: 'https://files.majelan.audio/v2/audios/trailers/sala_market_mix.mp3',
    position: 0,
  },
]

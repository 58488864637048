import React, { ReactNode } from 'react'

import NextHead from 'next/head'

import { sanitize } from '../../../utils'
import { useIntl } from 'react-intl'

export interface HeadProps {
  metas?: any | null
  children?: ReactNode
  asPath?: string
}

export const Head = ({ metas: metasProps, children, asPath }: HeadProps) => {
  const { formatMessage } = useIntl()

  const metas = metasProps || {
    title: formatMessage({ id: 'metas.global.title' }),
    description: formatMessage({ id: 'metas.global.description' }),
    internalPictureURL:
      'https://images.majelan.audio/divers/v2/MAJELAN_HOME.png?w=1200&h=630&fm=jpg&q=70',
    horizontalPictureURL:
      'https://images.majelan.audio/divers/v2/MAJELAN_HOME.png?w=1200&h=630&fm=jpg&q=70',
    verticalPictureURL:
      'https://images.majelan.audio/divers/v2/MAJELAN_HOME.png?w=1200&h=630&fm=jpg&q=70',
  }

  const image = metas?.horizontalPictureURL || metas?.internalPictureURL

  const { title, description } = {
    title: `${process.env.NEXT_PUBLIC_APP_NAME || 'Majelan'} - ${sanitize(
      (metas.title === formatMessage({ id: metas.title })
        ? metas.title
        : formatMessage({ id: metas.title })) || '',
    )}`,
    description: sanitize(metas?.description || ''),
  }

  return (
    <NextHead>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, minimum-scale=1.0, shrink-to-fit=no"
      />
      <meta charSet="utf-8" />
      <base target="_top" />
      <link rel="canonical" href={`${process.env.NEXT_PUBLIC_MAJELAN_URL}${asPath}`} />
      <link rel="alternate" href={`android-app://${process.env.NEXT_PUBLIC_PLAYSTORE_ID}`} />
      <link rel="alternate" href={`ios-app://${process.env.NEXT_PUBLIC_APPSTORE_ID}`} />
      <meta property="al:android:app_name" content={process.env.NEXT_PUBLIC_APP_NAME} />
      <meta property="al:android:package" content={process.env.NEXT_PUBLIC_PLAYSTORE_ID} />
      <meta
        property="al:android:url"
        content={`${process.env.NEXT_PUBLIC_APP_DEEP_LINK_SCHEME}:/${asPath}`}
      />
      <meta property="al:ios:app_name" content={process.env.NEXT_PUBLIC_APP_NAME} />
      <meta property="al:ios:app_store_id" content={process.env.NEXT_PUBLIC_APPSTORE_ID} />
      <meta
        property="al:ios:url"
        content={`${process.env.NEXT_PUBLIC_APP_DEEP_LINK_SCHEME}:/${asPath}`}
      />
      {/* Google / Search Engine Tags */}
      <meta property="google" content="notranslate" />
      {/* Facebook Meta Tags */}
      <meta property="og:url" content={`${process.env.NEXT_PUBLIC_MAJELAN_URL}${asPath}`} />
      <meta property="og:site_name" content={process.env.NEXT_PUBLIC_APP_NAME} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="fb:app_id" content={process.env.NEXT_PUBLIC_FACEBOOK_ID} />
      {/* Twitter Meta Tags */}
      <meta name="twitter:creator" content={process.env.NEXT_PUBLIC_MAJELAN_TWITTER_ACCOUNT} />
      <meta name="twitter:site" content={process.env.NEXT_PUBLIC_MAJELAN_TWITTER_ACCOUNT} />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="application-name" content={process.env.NEXT_PUBLIC_APP_NAME} />
      <meta name="apple-mobile-web-app-title" content={process.env.NEXT_PUBLIC_APP_NAME} />
      <meta name="theme-color" content="#343546" />
      <meta name="msapplication-navbutton-color" content="#343546" />
      <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
      <meta name="msapplication-starturl" content={process.env.NEXT_PUBLIC_MAJELAN_URL} />
      <link
        rel="apple-touch-icon"
        type="image/png"
        sizes="100x100"
        href={`/${process.env.NEXT_PUBLIC_FAVICON}.png`}
      />
      <link rel="apple-touch-icon" type="image/png" sizes="200x200" href="/logo@2x.png" />
      <link rel="apple-touch-icon" type="image/png" sizes="300x300" href="/logo@3x.png" />
      <link
        rel="icon"
        type="image/png"
        sizes="100x100"
        href={`/${process.env.NEXT_PUBLIC_FAVICON}.png`}
      />
      <link rel="icon" type="image/png" sizes="200x200" href="/logo@2x.png" />
      <link rel="icon" type="image/png" sizes="300x300" href="/logo@3x.png" />
      <link rel="shortcut icon" href={`/${process.env.NEXT_PUBLIC_FAVICON}.ico`} />
      <link rel="manifest" href="/manifest.json" />
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* Google / Search Engine Tags */}
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={description} />
      <meta itemProp="image" content={image} />
      {/* Facebook Meta Tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:image:alt" content={title} />
      {/* Twitter Meta Tags */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:image:alt" content={title} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="og:type" content="website" />
      {children}
    </NextHead>
  )
}

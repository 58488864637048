import React, { ReactNode, useEffect, useState } from 'react'

import styled, { css } from 'styled-components'

export interface LoaderProps {
  loading: boolean
}

export interface OrientationSource {
  portrait: string
  landscape: string
}

export interface ImageProps {
  src: string | OrientationSource
  width?: number
  height?: number
  opacity?: number
  radius?: number
  aspectRatio?: number
  children?: ReactNode
  resizeMode?: any
  options?: any
  sizes?: string
  alt: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

export const ImageContainer = styled.picture<Pick<ImageProps, 'radius'>>`
  max-width: 100%;
  width: 100%;

  position: relative;

  ${({ radius }) =>
    radius &&
    css`
      border-radius: ${radius}px;
      overflow: hidden;
    `}
`

export const ImageChild = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export const ImageStyled = styled.img<Omit<ImageProps, 'src'>>`
  width: 100%;
  height: 100%;
  max-width: 100%;

  ${({ resizeMode }) =>
    resizeMode &&
    css`
      object-fit: ${resizeMode};
    `}

  ${({ radius }) =>
    radius &&
    css`
      border-radius: ${radius}px;
      overflow: hidden;
    `}


  ${({ width, aspectRatio }) =>
    width &&
    !aspectRatio &&
    css`
      width: ${width}px;
    `}

  ${({ height, aspectRatio }) =>
    height &&
    !aspectRatio &&
    css`
      height: ${height}px;
    `}
`

function isOrientationSource(object: any): object is OrientationSource {
  return typeof object !== 'string'
}

export const Image = ({
  src,
  options = { fm: 'png' },
  resizeMode = 'cover',
  alt = '',
  sizes = '100vw',
  width,
  height,
  aspectRatio,
  radius,
  children,
  ...props
}: ImageProps) => {
  const generateSrcSet = (source: string, srcSetOptions: any): string =>
    [320, 480, 640, 800, 960, 1120, 1440, 1760, 2080, 2560]
      .map((widthSize: number) => {
        // const newSet = Imgix.format(
        //   {
        //     uri: source,
        //   },
        //   { width: widthSize },
        //   { ...srcSetOptions },
        // )

        return `''${`${widthSize}w`}`
      })
      .join(', ')

  const [formattedSource, setFormattedSource] = useState<string>()

  const fm = options.fm || (options.bg === 'transparent' && 'png') || undefined

  const hasOrientation = isOrientationSource(src)

  useEffect(() => {
    setFormattedSource(generateSrcSet(isOrientationSource(src) ? src.landscape : src, options))
  }, [src, options])

  return (
    <ImageContainer {...(props as any)} radius={radius}>
      {Object.entries(hasOrientation ? src : { src }).map(([orientation, source], key) => {
        const newSet = generateSrcSet(source, {
          ...options,
          fm: 'webp',
        })
        const newSetFallback = generateSrcSet(source, {
          ...options,
          fm,
        })

        const media = [...[hasOrientation && `(orientation: ${orientation})`]]
          .filter(value => typeof value === 'string')
          .join(' and ')

        return (
          <>
            <source
              type="image/webp"
              srcSet={newSet}
              sizes={sizes}
              key={`source-srcset_${orientation}_${alt}_${key}`}
              {...(media && { media })}
            />
            <source
              srcSet={newSetFallback}
              sizes={sizes}
              key={`source-srcset_fallback_${orientation}_${alt}_${key}`}
              {...(fm && { type: `image/${fm}` })}
              {...(media && { media })}
            />
          </>
        )
      })}
      <ImageStyled
        width={width}
        height={height}
        aspectRatio={aspectRatio}
        resizeMode={resizeMode}
        alt={alt}
        radius={radius}
        srcSet={formattedSource}
        loading="lazy"
        sizes={sizes}
      />
      {children && <ImageChild>{children}</ImageChild>}
    </ImageContainer>
  )
}

import { Field } from 'formik'
import styled, { css } from 'styled-components'

import { Icon } from './Icon'
import { InputProps, InputType } from './Input'

export const InputContainer = styled.div`
  flex-direction: row;
  flex-grow: 1;
  position: relative;
`

export const InputField = styled(Field)<InputProps>`
  height: 47px;

  color: ${({ theme }) => theme.quaternaryColor};

  ::placeholder {
    color: ${({ theme }) => theme.secondaryColor};
  }

  :focus {
    color: ${({ theme }) => theme.quaternaryColor};
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: ${({ theme }) => theme.primaryColor};
  }

  border-width: 0;
  border-style: solid;

  ${({ inputType, editable }) =>
    (inputType === InputType.ROUNDED &&
      css`
        border-width: 1px;
        border-radius: 37px;
        background-color: ${({ theme }) =>
          editable ? theme.backgroundColorLess1 : theme.primaryBackgroundColor};
        border-color: ${({ theme }) => (editable ? '#ffffff' : '#000000')};

        :focus {
          border-color: ${({ theme }) => theme.primaryColor};
        }
      `) ||
    css`
      background-color: ${({ theme }) =>
        editable ? theme.primaryBackgroundColor : theme.primaryBackgroundColor};
      border-color: ${({ theme }) => '#ffffff'};
      border-bottom-width: 1px;
    `}

  width: 100%;
  padding: 5px ${({ inputType }) => (inputType === InputType.ROUNDED && 15) || 0}px;
  letter-spacing: 0.34px;
  font-size: 14px;

  ${({ meta, theme, displayError }) =>
    !!meta.error &&
    displayError &&
    css`
      border-color: ${theme.red};
    `}

  transition: border 0.2s linear;
`

export const ButtonContainer = styled.div`
  position: absolute;
  display: flex;
  height: 100%;
  top: 0;
  right: 0;
  padding: 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const IconInput = styled(Icon).attrs(({ theme }) => ({
  color: theme.primaryColor,
}))`
  color: ${({ theme }) => theme.primaryColor};
  padding-right: 5px;
`

export const CheckImage = styled(IconInput)``

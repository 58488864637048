import { Link } from './Link'

const SCALE_RATIO = 3.2

const badges = {
  IOS: {
    href: 'https://go.majelan.com/website-ios-download',
    src: '/badge-apple-fr.png',
    alt: 'badge-ios',
  },
  ANDROID: {
    href: 'https://go.majelan.com/website-android-download',
    src: '/badge-google-fr.png',
    alt: 'badge-android',
  },
}

export enum BadgeType {
  IOS = 'IOS',
  ANDROID = 'ANDROID',
}

interface BadgeProps {
  type: BadgeType
  size: BadgeSize
  onClick?: () => void
}

export enum BadgeSize {
  Medium = 50,
  Large = 75,
}

export const Badge = ({ type, size = BadgeSize.Medium, ...props }: BadgeProps) => {
  const { src, href = '', alt } = badges[type]

  return (
    <Link href={href} className="my-5" {...props}>
      <img src={src} alt={alt} height={size} width={size * SCALE_RATIO} />
    </Link>
  )
}

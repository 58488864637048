import { requestQuery } from '.'

export const getCheckCoupon = (code: string) =>
  requestQuery({
    method: 'GET',
    url: `/virtual/coupons/${code}`,
  })

export const postActivateCoupon = (code: any) =>
  requestQuery({
    method: 'POST',
    url: `/virtual/premiums`,
    data: {
      receipt: { code },
      type: 'coupon',
    },
  })

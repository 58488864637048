import React, { PropsWithChildren } from 'react'

import { ImageCms } from '../cms'

export interface BlockHighProps {
  data: any
  ratio?: any
  premium?: boolean
  showTitle?: boolean
  showKeyword?: boolean
  showPremium?: boolean
  radius?: number
  shadow?: boolean
  sizes?: string
}

export const BlockHigh = ({
  data,
  data: { keyword1, title },
  showTitle,
  showKeyword,
}: PropsWithChildren<BlockHighProps>) => {
  return (
    <div>
      <ImageCms
        src={data.verticalPictureURL ?? data.internalPictureURL}
        alt={title}
        sizes="(min-width: 768px) 200px, 50vw"
        width="143"
        height="193"
        className="w-full"
        imageClassName="w-full rounded-md"
      />
      {(showKeyword || showTitle) && (
        <div className="justify-start pt-3 pr-1 pb-1 pl-1 text-left">
          <div className="flex justify-start items-center">
            {showKeyword && !!keyword1 && (
              <span className="text-cloud leading-normal font-medium">{keyword1}</span>
            )}
          </div>
          {showTitle && (
            <p className="text-white leading-normal text-sm my-1 font-medium">{title}</p>
          )}
        </div>
      )}
    </div>
  )
}

import axios from 'axios'
import moment from 'moment'
import { DateTime } from 'luxon'
import { config } from './config'

export interface ComputeAnalytics {
  eventKey: ANALYTICS_KEYS
  category: ANALYTICS_CATEGORIES
  sessionEvent: any
}

export enum ANALYTICS_KEYS {
  SUBSCRIPTION_CHECKOUT = 'begin_checkout',
  SUBSCRIPTION_PURCHASE = 'purchase',
  SUBSCRIPTION_ADD_CART = 'add_to_cart',
  SUBSCRIPTION_FREE_SIGNUP = 'free_signup',
  SUBSCRIPTION_CLICK_SUBSCRIBE = 'click_subscribe_CTA',
  CONNEXION_REGISTRATION = 'sign_up',
  CLICK_CTA = 'click_CTA',
  ANDROID = 'android',
  IOS = 'ios',
  OPTIMIZE_ACTIVATE = 'optimize.activate',
  PLAY_TRAILER = 'play_trailer',
  CATALOG_FILTER_FORMAT = 'catalog_filter_format',
  BUSINESS_HEADER_FORM = 'cta_header_form',
  BUSINESS_BANNER = 'cta_banner_form',
  BUSINESS_BLOCK = 'cta_block_form',
  BUSINESS_FORM = 'submit_form',
}

export enum ANALYTICS_CATEGORIES {
  SUBSCRIPTION = 'subscription',
  CONNEXION = 'connexion',
  MARKETING = 'marketing',
  DOWNLOAD = 'download',
  PRODUCT_USE = 'Product use',
  BUSINESS = 'majelan_business',
}

export enum ANALYTICS_FACEBOOK_KEYS {
  SUBSCRIPTION_CHECKOUT = 'InitiateCheckout',
  SUBSCRIPTION_PURCHASE = 'Purchase',
  SUBSCRIPTION_ADD_CART = 'AddToCart',
  SUBSCRIPTION_FREE_SIGNUP = 'FreeSignUp',
  CONNEXION_REGISTRATION = 'CompleteRegistration',
}

export const ANALYTICS_FACEBOOK_KEYS_MAP = {
  [ANALYTICS_KEYS.SUBSCRIPTION_CHECKOUT]: ANALYTICS_FACEBOOK_KEYS.SUBSCRIPTION_CHECKOUT,
  [ANALYTICS_KEYS.SUBSCRIPTION_PURCHASE]: ANALYTICS_FACEBOOK_KEYS.SUBSCRIPTION_PURCHASE,
  [ANALYTICS_KEYS.SUBSCRIPTION_ADD_CART]: ANALYTICS_FACEBOOK_KEYS.SUBSCRIPTION_ADD_CART,
  [ANALYTICS_KEYS.CONNEXION_REGISTRATION]: ANALYTICS_FACEBOOK_KEYS.CONNEXION_REGISTRATION,
  [ANALYTICS_KEYS.SUBSCRIPTION_FREE_SIGNUP]: ANALYTICS_FACEBOOK_KEYS.SUBSCRIPTION_FREE_SIGNUP,
}

export enum ANALYTICS_LABEL {
  SUBSCRIPTION_FREE_SIGNUP = 'free',
  SUBSCRIPTION_CLICK_SUBSCRIBE_HOME_BANNER = 'home_hero',
  SUBSCRIPTION_CLICK_SUBSCRIBE_HEADER = 'nav_header',
  SUBSCRIPTION_CLICK_SUBSCRIBE_MY_ACCOUNT_FREE = 'my_account_free',
  BANNER_MARKET_HOME = 'banner_market_home',
  BANNER_GIFT_OFFERS = 'banner_gift_offers',
  WEBAPP_COMING_SOON = 'webapp_coming_soon',
  THANK_YOU_PAGE = 'thank_you_page',
  PUSH_NOTIF = 'push_notif',
  WEB_APP_PAGE_COMING_SOON = 'webapp_page_coming_soon',
  ACTIVATION_COUPON = 'activation_coupon',
  PROGRAM_PAGE = 'program_page',
  PROMO_EXPIRED = 'promo_expired',
}

function isCheckoutType(object: any): object is any {
  return 'price' in object
}

export const computeAnalyticsTrackEvent = (
  eventKey: ANALYTICS_KEYS,
  category: ANALYTICS_CATEGORIES,
  sessionEvent: any, //CheckoutSessionEvent,
): void => {
  const timestamp = moment().format('x')
  const data: any = {
    timestamp,
    event_sender: 'web',
    ...sessionEvent,
  }

  const value = (data?.price && data.price / 100) || 0
  const currencyValue = data.currency?.toUpperCase()

  // GTAG
  window.gtag('event', eventKey, {
    event_category: category,
    event_label: data.name,
    value,
    ...(sessionEvent && {
      ...data,
      currency: currencyValue,
      ...(isCheckoutType(sessionEvent) && {
        transaction_id: data.sessionUUID,
        items: [data.priceId],
      }),
    }),
  })

  const [, fbMapKey]: any[] = Object.entries(ANALYTICS_FACEBOOK_KEYS_MAP).find(
    ([key]) => key === eventKey,
  ) || [null, eventKey]

  window.fbq(
    'track',
    fbMapKey,
    sessionEvent && {
      ...(isCheckoutType(sessionEvent) && {
        content_name: data.name,
        content_type: data.type,
        content_ids: [data.productId, data.priceId],
        num_items: data.quantity,
        currency: currencyValue,
        value: value.toFixed(2),
        content_category: category,
      }),
    },
  )
}

export const sendBatchEvent = (userUUID, eventName, eventAttributes) => {
  axios
    .post('/api/event', {
      userUUID,
      eventName: `ue.${eventName}`,
      eventAttributes,
    })
    .catch(() => {
      //
    })
}

import axios from 'axios'
import { useRouter } from 'next/router'
import { createContext, useCallback, useContext, useEffect, useState } from 'react'

import { retrieveSession, setSession, StorageKeys } from 'src'

import { useAuth } from './AuthProvider'

export const OfferContext = createContext<{
  checkoutElement: any
  addCheckoutElement: any
  resetCheckoutElement: any
}>({
  checkoutElement: null,
  addCheckoutElement: () => {},
  resetCheckoutElement: () => {},
})

export const OfferProvider = ({ children }) => {
  const { user } = useAuth()
  const { asPath, push, query } = useRouter()

  const [checkoutElement, setCheckoutElement] = useState(() =>
    typeof window !== 'undefined' ? retrieveSession(StorageKeys.OFFER) : null,
  )

  const handleSaveCheckoutElement = useCallback(checkout => {
    setCheckoutElement(checkout)
    setSession(StorageKeys.OFFER, checkout)
  }, [])

  const resetCheckoutElement = useCallback(() => handleSaveCheckoutElement(null), [
    handleSaveCheckoutElement,
  ])

  const handleNewCheckoutSession = useCallback(
    checkout => {
      if (user?.premium) return

      axios
        .post('/api/checkout_sessions', {
          customer: user?.stripeID,
          line_items: checkout.items.map(({ id: price }: { id: string }) => ({
            price,
            quantity: 1,
          })),
          ...(checkout.code && {
            discounts: [{ promotion_code: checkout.code }],
          }),
          ...(checkout.trialPeriod &&
            (!user?.hasBeenPremium ?? true) && {
              subscription_data: { trial_period_days: checkout.trialPeriod },
            }),
          redirectUrl: checkout.redirectUrl,
        })
        .then(res => push(res.data.url))
        .catch(err => {
          resetCheckoutElement()
          console.log('STRIPE CHECKOUT ERROR', err)
        })
    },
    [push, resetCheckoutElement, user?.stripeID, user?.premium],
  )

  const addCheckoutElement = checkout => {
    handleSaveCheckoutElement(checkout)

    if (user?.premium) {
      resetCheckoutElement()
      push(`${process.env.NEXT_PUBLIC_WEBAPP_URL}/account/subscription`)
      return
    }

    if (user?.gcu && !user?.premium) {
      handleNewCheckoutSession(checkout)
      return
    }

    push({
      pathname: `${process.env.NEXT_PUBLIC_WEBAPP_URL}/auth/signup`,
      query: { continue: 'offers' },
    })
  }

  useEffect(() => {
    if (checkoutElement && asPath.includes('app/subscription/success')) {
      resetCheckoutElement()
      return
    }

    if (checkoutElement && user?.gcu && !user?.premium && query?.continue === 'offers') {
      handleNewCheckoutSession(checkoutElement)
    }
  }, [user, checkoutElement, asPath, handleNewCheckoutSession, resetCheckoutElement, query])

  return (
    <OfferContext.Provider
      value={{
        checkoutElement,
        addCheckoutElement,
        resetCheckoutElement,
      }}
    >
      {children}
    </OfferContext.Provider>
  )
}

export const useOffer = () => useContext(OfferContext)

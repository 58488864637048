import Link from 'next/link'
import classnames from 'classnames'

import { BlockHighCms, CtaStripe, SecondaryTitle, TextDocument } from '.'
import { Button } from '../atoms/button/Button'

export interface ProgramListCmsProps {
  displayType: string
  title: string
  description: any
  programsCollection: ProgramsCollectionProps
  cta: any // CtaContentful | CtaStripeContentful
}

interface ProgramsCollectionProps {
  items: ItemsProgramsProps[]
}

interface ItemsProgramsProps {
  title: string
  uuid: string
  coverHigh: any //AssetContentful
}

export const ProgramListCms = ({
  displayType,
  title,
  description,
  programsCollection,
  cta,
}: ProgramListCmsProps) => {
  const blocks = programsCollection.items.map((program, key) => (
    <div className="w-36 md:w-44" key={`block_${key}`}>
      <Link href={`/programs/${program.uuid}`} key={`catalog-cover_${key}`}>
        <a>
          <BlockHighCms title={program.title} image={program.coverHigh} />
        </a>
      </Link>
    </div>
  ))

  const renderCta = () => {
    switch (cta?.__typename) {
      case 'Cta':
        return (
          <Button className="mt-5" title={cta.label} href={cta.url} buttonType={cta.displayType}>
            {cta.label}
          </Button>
        )

      case 'CtaStripe':
        return (
          <CtaStripe
            label={cta.label}
            priceId={cta.priceId}
            promoCode={cta.promoCode}
            trialPeriod={cta.trialPeriod}
          />
        )

      default:
        return null
    }
  }

  return (
    <div>
      <div className="px-5 md:px-24 lg:px-44">
        <div
          className={classnames('flex items-center justify-center flex-col md:px-3 lg:px-0', {
            'md:flex-row': displayType === 'textLeft',
            'md:flex-row-reverse': displayType !== 'textLeft',
          })}
        >
          <div
            className={classnames('px-5 md:px-0', {
              'md:pr-10 lg:pr-20': displayType === 'textLeft',
              'md:pl-10 lg:pl-20': displayType !== 'textLeft',
            })}
          >
            {title && <SecondaryTitle text={title} className="text-center md:text-left pb-3" />}
            {description && (
              <TextDocument text={description.json} className="text-center md:text-left" />
            )}
            {cta && <div className="hidden md:block w-max">{renderCta()}</div>}
          </div>
          {programsCollection && (
            <div className="flex flex-row gap-x-5 md:gap-x-10 gap-y-5 pt-10 justify-center">
              {blocks}
            </div>
          )}
          {cta && <div className="md:hidden w-max">{renderCta()}</div>}
        </div>
      </div>
    </div>
  )
}

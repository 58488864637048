import pkg from 'package.json'

export const config = {
  version: pkg.version,
  API_URL: process.env.NEXT_PUBLIC_API_URL,
  API_VERSION: process.env.NEXT_PUBLIC_API_VERSION,
  PARTNER_BYTEL_CLIENT_ID: process.env.NEXT_PUBLIC_PARTNER_BYTEL_CLIENT_ID,
  PARTNER_BYTEL_AUTHORIZE: process.env.NEXT_PUBLIC_PARTNER_BYTEL_AUTHORIZE_URL,
  PARTNER_BYTEL_THANKS: process.env.NEXT_PUBLIC_PARTNER_BYTEL_THANKS_URL,
  PARTNER_BYTEL_ACCOUNT: process.env.NEXT_PUBLIC_PARTNER_BYTEL_ACCOUNT_URL,
  APP_URL: process.env.NEXT_PUBLIC_MAJELAN_URL,
  BATCH_API_KEY: process.env.BATCH_API_KEY,
  BATCH_API_REST_KEY: process.env.BATCH_API_REST_KEY,
}

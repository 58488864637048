import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { useCallback, useState, useMemo } from 'react'
import classnames from 'classnames'
import Link from 'next/link'

import { BlockHighCms } from '.'

type ThemesListCmsProps = {
  title: string
  description: any
  themes: any //ThemeContentful[]
}

export const ThemesListCms = ({ title, description, themes }: ThemesListCmsProps) => {
  const [currentTheme, setCurrentTheme] = useState(themes[0].title)

  const changeTheme = useCallback(theme => {
    setCurrentTheme(theme)
  }, [])

  const progamsCollectionSelected = useMemo(
    () =>
      themes.find(({ title: themeTitle }) => themeTitle === currentTheme)?.programsCollection
        .items || [],
    [currentTheme, themes],
  )

  return (
    <div>
      <div className="px-5 md:px-24 lg:px-44">
        <div className="m-auto max-w-4xl text-center">
          <h1 className="pb-3">{title}</h1>
          <p className="text-cloud text-lg">{documentToReactComponents(description)}</p>

          <div className="mt-10">
            <ul className="flex flex-row flex-wrap gap-y-5 gap-x-3 justify-center m-auto">
              {themes.map((theme, index) => (
                <li key={`ThemesListCms-${index}`}>
                  <button
                    color={theme.color}
                    type="button"
                    className={classnames(
                      'px-10 py-3 border border-solid rounded-3xl uppercase text-sm relative overflow-hidden group',
                    )}
                    style={{ borderColor: theme.color }}
                    onClick={() => changeTheme(theme.title)}
                  >
                    <span
                      className={classnames(
                        'w-full absolute inset-0 -z-1 transition-transform duration-300 transform',
                        {
                          'translate-x-0': currentTheme === theme.title,
                          '-translate-x-full group-hover:translate-x-0':
                            currentTheme !== theme.title,
                        },
                      )}
                      style={{ backgroundColor: theme.color }}
                    />
                    <span className="z-10">{theme.title}</span>
                  </button>
                </li>
              ))}
            </ul>
            <div className="flex flex-wrap gap-x-5 md:gap-x-10 gap-y-5 pt-10 justify-center">
              {progamsCollectionSelected.map(
                ({ title: titleProgram, coverHigh, format, uuid }: any) => (
                  // ProgramContentful
                  <div className="w-36 md:w-44" key={`themeList-${currentTheme}-program-${uuid}`}>
                    <Link href={`/programs/${uuid}`} locale={'fr'}>
                      <a>
                        <BlockHighCms
                          title={titleProgram}
                          image={coverHigh}
                          format={format.title}
                        />
                      </a>
                    </Link>
                  </div>
                ),
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

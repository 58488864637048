import React from 'react'

import { FieldProps } from 'formik'

import { ButtonContainer, CheckImage, IconInput, InputContainer, InputField } from './Input.styled'

export enum InputType {
  ROUNDED = 'rouded',
  MATERIAL = 'material',
}

export interface InputProps extends Pick<FieldProps, 'meta'> {
  cancellable?: boolean
  defaultValue?: string
  displayCheck?: boolean
  displayError?: boolean
  inputType?: InputType
  name: string
  value?: string
  editable?: boolean
  reset?: () => void
  placeholder?: string
}

export const Input = ({
  cancellable = false,
  inputType = InputType.ROUNDED,
  value,
  editable = true,
  displayCheck,
  reset,
  meta,
  ...props
}: InputProps) => (
  <InputContainer>
    <InputField
      {...{ meta }}
      {...props}
      disabled={!editable}
      editable={editable}
      inputType={inputType}
      value={value}
    />
    {!!value && (cancellable || displayCheck) && (
      <ButtonContainer>
        {/* {cancellable && (
          // <IconInput name="close" size={Sizes.Tiny} inversed onClick={reset} />
        )} */}
        {/* {displayCheck && <CheckImage name="check" size={Sizes.Tiny} />} */}
      </ButtonContainer>
    )}
  </InputContainer>
)

import React from 'react'

import { Button } from '../atoms/button/Button'
import { CtaStripe, TextDocument } from '.'
import { computeAnalyticsTrackEvent } from 'src/utils/analytics'

export interface RichCtaProps {
  title: string
  subtitle?: any
  name: string
  cta: any
}

export const RichCta = ({ title, subtitle, name, cta }: RichCtaProps) => {
  return (
    <div>
      <div className="max-w-screen-lg m-auto px-3 lg:px-0">
        <div className="flex flex-col md:flex-row justify-around items-center border border-solid rounded-xl w-full border-cloud p-5 md:py-11 md:px-5">
          <h3 className="pb-10 md:pb-0 text-xl text-center font-bold text-cloud">{name}</h3>
          <div className="flex flex-col md:px-10 pb-10 md:pb-0">
            <h3 className="text-xl text-center font-bold text-cloud">{title}</h3>
            {subtitle && <TextDocument className="text-center text-sm" text={subtitle.json} />}
          </div>
          <div className="flex">
            {cta.__typename === 'Cta' ? (
              <Button
                title={cta.label}
                href={cta.url}
                buttonType={cta.displayType}
                onClick={() => {
                  if (cta.analytics) {
                    computeAnalyticsTrackEvent(cta.analytics.eventName, cta.analytics.categorie, {
                      name: cta.analytics.label,
                    })
                  }
                }}
                scroll={!cta.url.includes('#')}
              >
                {cta.label}
              </Button>
            ) : (
              <CtaStripe
                label={cta.label}
                priceId={cta.priceId}
                promoCode={cta.promoCode}
                trialPeriod={cta.trialPeriod}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

import { useAuth } from '../providers/AuthProvider'
import { productBlocksItems, SubscriptionCard } from '../molecules/SubscriptionCard'

export interface ProducList {
  productBlocksCollection: productBlocksItems[]
}

export const ProductList = (productBlocksCollection: ProducList) => {
  const { user } = useAuth()

  return (
    <section>
      <div className="flex flex-wrap justify-center items-start w-full pt-10">
        {productBlocksCollection.productBlocksCollection.map((product, index) => (
          <div className="m-10" key={`product-${index}`}>
            <SubscriptionCard
              product={
                user?.premium || user?.hasBeenPremium || false
                  ? product.hasBeenPremiumReplacement || product
                  : product
              }
              key={index}
            />
          </div>
        ))}
      </div>
    </section>
  )
}

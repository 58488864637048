import moment from 'moment'
import qs from 'query-string'
import { chunk, fromPairs, isEmpty } from 'lodash'

export type PlatformName = 'android' | 'ios' | 'web'
export type PlatformRealOS = 'android' | 'ios' | 'macos' | 'windows' | 'linux'

export const convertDurationMin = (
  duration: number,
  options: { withSeconds: boolean } = { withSeconds: false },
): string => {
  const milliseconds = duration * 1000
  const seconds = moment.duration(milliseconds).seconds()
  const minutes = moment.duration(milliseconds).minutes()
  const hours = moment.duration(milliseconds).hours()

  return [
    ...[hours && hours + ' h'],
    ...[minutes && minutes + ' min'],
    ...[seconds && options.withSeconds && seconds + ' sec'],
  ]
    .filter(Boolean)
    .join(' ')
}

export const serialize = (value: string) => {
  try {
    return JSON.parse(value)
  } catch (error) {
    // Check if value contain object or array
    return !/(\{|\[)/.test(value) ? value : null
  }
}

export const serializeMetadata = (metadata: object = {}) => {
  try {
    return Object.entries(metadata).reduce(
      (aggr, [key, value]) => ({
        ...aggr,
        [key]: serialize(value),
      }),
      {},
    )
  } catch (e) {
    return {}
  }
}

export const getOSName = (): PlatformRealOS | undefined => {
  const userAgent =
    typeof navigator === 'undefined' || typeof window === 'undefined'
      ? ''
      : navigator.userAgent || navigator.vendor || (window as any).opera || ''

  if (/android/i.test(userAgent)) return 'android'

  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) return 'ios'

  const platform =
    typeof navigator === 'undefined' ? '' : `${navigator.platform || ''}`.toLowerCase().trim()

  if (platform.startsWith('mac')) return 'macos'
  if (platform.startsWith('win')) return 'windows'
  if (platform.startsWith('linux')) return 'linux'

  return undefined
}

export const realOS = () => getOSName() || 'web'

export const passwordRuleLength = /(?=.{8,})/
export const passwordRuleUppercase = /(?=.*[A-Z])/
export const passwordRuleLowercase = /(?=.*[a-z])/
export const passwordRuleNumber = /(?=.*[0-9])/
export const passwordRuleSpecial = /(?=.*[^0-9a-zA-Z])/

export const validatePasswordRule = (
  value: string,
  rule: 'length' | 'uppercase' | 'lowercase' | 'number' | 'special',
) => {
  switch (rule) {
    case 'length':
      return passwordRuleLength.test(value)

    case 'uppercase':
      return passwordRuleUppercase.test(value)

    case 'lowercase':
      return passwordRuleLowercase.test(value)

    case 'number':
      return passwordRuleNumber.test(value)

    case 'special':
      return passwordRuleSpecial.test(value)

    default:
      return false
  }
}

export const sanitize = (value: string) => value.replace(/(<([^>]+)>)/gi, '')

export const getQueryState = () => {
  if (typeof window === 'undefined') {
    return null
  }

  const query = qs.parse(window.location.hash || window.location.search)

  if (isEmpty(query)) return { query: null, state: null }

  return {
    query,
    state: query?.state ? fromPairs(chunk((query.state as string).split(','), 2)) : null,
  }
}

import { convertDurationMin } from 'src'

export interface MediaCardProps {
  media: any
  separator?: boolean
}

export const MediaCard = ({ media, separator }: MediaCardProps) => {
  const { title, episode, duration } = media

  const episodeTimeLength = convertDurationMin(duration)

  return (
    <div className="flex space-x-3">
      <div className="flex items-start justify-start relative">
        <div className="w-10 h-10 flex items-center justify-center rounded-full bg-shark relative z-10">
          <p className="text-white">{episode}</p>
        </div>
        {separator && (
          <div className="flex overflow-hidden w-0.5 h-24">
            <div className="absolute top-0 left-1/2 transform -translate-x-1/2 right-0 w-0.5 h-full bg-shark" />
          </div>
        )}
      </div>
      <div>
        <h3 className="text-base text-white font-normal">{title}</h3>
        <p className="text-xs">{episodeTimeLength}</p>
      </div>
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import { TEST_IDS } from '../../../utils'

enum BannerTypes {
  WHITE = 'WHITE',
  SHARK = 'SHARK',
}

enum ButtonType {
  PRIMARY = 'primary',
  NEUTRAL = 'neutral',
  DANGER = 'danger',
  TRANSPARENT = 'transparent',
}

import {
  BannerCloseIcon,
  BannerCloseWrapper,
  BannerLabel,
  BannerMessageContainer,
  BannerMessageStyled,
  BorderGradient,
  ButtonStyled,
} from './BannerMessage.style'

export interface BannerMessageType extends Omit<any, 'isActive'> {}

export const HIDE_TIME = 200

export const BannerMessage = ({ title, type, isClosable, cta, ...props }: BannerMessageType) => {
  const { formatMessage: getI18nKey } = useIntl()

  const [showBanner, setShowBanner] = useState(true)
  const [deleteBanner, setDeleteBanner] = useState(false)

  useEffect(() => {
    setShowBanner(true)
  }, [])

  useEffect(() => {
    if (!showBanner) {
      setTimeout(() => setDeleteBanner(true), HIDE_TIME)
    }
  }, [showBanner])

  return (
    <>
      {!deleteBanner && (
        <BannerMessageStyled {...{ type, showBanner }} data-testid={TEST_IDS.COMPONENTS.BANNER}>
          <BannerMessageContainer {...props}>
            {type === BannerTypes.SHARK && <BorderGradient />}
            <BannerLabel {...{ type }}>{getI18nKey({ id: title })}</BannerLabel>
            {cta && (
              <ButtonStyled
                title={cta.label}
                buttonType={ButtonType.NEUTRAL}
                data-testid="banner_button"
                href={cta.url}
              />
            )}
          </BannerMessageContainer>
          {isClosable && (
            <BannerCloseWrapper
              buttonType={ButtonType.TRANSPARENT}
              onClick={() => setShowBanner(false)}
              data-testid="banner_closable"
            >
              <BannerCloseIcon name="close-off" size={20} {...{ type }} />
            </BannerCloseWrapper>
          )}
        </BannerMessageStyled>
      )}
    </>
  )
}

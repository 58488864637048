import { nanoid } from 'nanoid'
import { createContext, useContext, useState } from 'react'

export const NOTIFICATIONS = {
  signin: {
    type: 'ACTION',
    title: 'common.notifications.welcome.signIn',
    delay: 0,
    duration: 5000,
  },
  signup: {
    type: 'ACTION',
    title: 'common.notifications.welcome.signUp',
    delay: 0,
    duration: 5000,
  },
  signInError: {
    type: 'ERROR',
    title: 'common.notifications.error.signIn.title',
    description: 'common.notifications.error.signIn.description',
    delay: 0,
    duration: 5000,
  },
  signInMagicLinkError: {
    type: 'ERROR',
    title: 'common.notifications.magicLink.expired',
    delay: 0,
    duration: 5000,
  },
  signInErrorPassword: {
    type: 'ERROR',
    title: 'common.notifications.error.signInPassword',
    delay: 0,
    duration: 5000,
  },
  signUpError: {
    type: 'ERROR',
    title: 'common.notifications.error.signUp.title',
    description: 'common.notifications.error.signUp.description',
    delay: 0,
    duration: 5000,
  },
  updateProfile: {
    type: 'ACTION',
    title: 'common.notifications.profile.updateSuccess',
    delay: 0,
    duration: 5000,
  },
  activateCode: {
    type: 'ERROR',
    title: 'common.notifications.coupon.unauthorized',
    delay: 0,
    duration: 5000,
  },
  resetPasswordErrorTimeout: {
    type: 'ERROR',
    title: 'common.notifications.resetPassword.error.timeout',
    delay: 0,
    duration: 5000,
  },
  resetPasswordSuccess: {
    type: 'ACTION',
    title: 'common.notifications.resetPassword.success',
    delay: 0,
    duration: 5000,
  },
  askResetPasswordSuccess: {
    type: 'ACTION',
    title: 'common.notifications.askResetPassword.success',
    delay: 0,
    duration: 5000,
  },
  unknown: {
    type: 'ERROR',
    title: 'common.notifications.error.unknown',
    delay: 0,
    duration: 5000,
  },
  CMSform: {
    type: 'ACTION',
    title: 'common.notifcations.hubspotForm.thanks',
    delay: 500,
    duration: 5000,
  },
}

export const NotificationContext = createContext<{
  notifications: any[]
  addNotification: any
  removeNotification: any
}>({
  notifications: [],
  addNotification: () => {},
  removeNotification: () => {},
})

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotification] = useState([])

  const addNotification = notification => {
    setNotification(prev => [...prev, { id: nanoid(), ...notification }])
  }

  const removeNotification = id => {
    setNotification(prev => prev.filter(({ id: notificationId }) => notificationId !== id))
  }

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        addNotification,
        removeNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  )
}

export const useNotification = () => useContext(NotificationContext)

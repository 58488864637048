import styled, { css, keyframes } from 'styled-components'

// import { Button } from '../button/Button'

import { Icon } from '../Icon'
import { BannerMessageType, HIDE_TIME } from './BannerMessage'
import { ButtonLabel, ButtonWrapper } from '../button/Button.style'

enum BannerTypes {
  WHITE = 'WHITE',
  SHARK = 'SHARK',
}

const BANNER_HEIGHT = 58
const BORDER_SIZE = 3
const DURATION_ANIMATION = 10

const borderGradientAnimation = keyframes`
  0% {
    left : 0%;
  }
  50% {
    left: -100%;
  }
  100% {
     left : 0%;
  }
`

const GRADIENT = css`
  content: '';
  position: absolute;
  left: 0;
  background-size: 100% 100%;
  animation: ${borderGradientAnimation} ${DURATION_ANIMATION}s ease infinite;
  width: 200%;
  height: ${BORDER_SIZE}px;
`

const bannerShowAnimation = keyframes`
  0% {
    transform: translateY(-50px);
    opacity: 0;
    visibility: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
    visibility: 1;
  }
`

const bannerRemoveAnimation = keyframes`
  0% {
    transform: translateY(0px);
    opacity: 1;
    visibility: 1;
  }
  100% {
    transform: translateY(-50px);
    opacity: 0;
    visibility: 1;
  }
`

export const BannerMessageContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  ${
    null
    // Tablet
  } {
    flex-direction: row;
  }
`

export const BannerMessageStyled = styled.div<
  Pick<BannerMessageType, 'type'> & { showBanner: boolean }
>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: 0;
  position: relative;

  padding: 20px;
  min-height: ${BANNER_HEIGHT}px;

  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.3);

  margin: 0;
  width: 100%;

  ${({ type, theme, showBanner }) => {
    const { backgroundThemeColor } = getBannerColors(type)

    return css`
      background-color: ${theme[backgroundThemeColor]};

      animation: ${showBanner ? bannerShowAnimation : bannerRemoveAnimation} ${HIDE_TIME}ms ease-out;
      animation-fill-mode: both;
    `
  }}
`

export const BannerLabel = styled.span<Pick<BannerMessageType, 'type'>>`
  text-align: center;
  line-height: 25px;
  font-weight: 450;

  ${({ type, theme }) =>
    (type === BannerTypes.SHARK &&
      css`
        font-size: 15px;
        padding: 0px 10px 10px 10px;
        color: ${theme.primaryColor};

        ${
          null
          // MQTablet
        } {
          font-size: 18px;
          padding: 10px;
        }
      `) ||
    css`
      font-size: 18px;
      padding: 0 30px;
      color: ${theme.backgroundColor};

      ${
        null
        // MQTablet
      } {
        font-size: 26px;
        padding: 0;
      }
    `}
`

export const BannerCloseWrapper = styled.button`
  padding: 0;
  margin: 0;

  position: absolute;
  top: 0;
  right: 0;

  min-width: inherit;

  ${
    null
    // MQFullHd
  } {
    position: relative;
  }
`

export const BannerCloseIcon = styled(Icon).attrs<Pick<BannerMessageType, 'type'>>(
  ({ type, theme }) => {
    const { foregroundThemeColor } = getBannerColors(type)
    return { color: theme[foregroundThemeColor] }
  },
)``

export const ButtonStyled = styled.button`
  ${
    null
    // ButtonWrapper
  } {
    background-color: ${({ theme }) => theme.primaryColor};

    ${
      null
      // ButtonLabel
    } {
      color: ${({ theme }) => theme.primaryColor};
    }

    &:before {
      background-color: ${({ theme }) => theme.shark};
    }
  }

  ${
    null
    // MQTablet
  } {
    margin-left: 20px;
  }
`

export const BorderGradient = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &:before {
    ${GRADIENT}
    top: 0;
    background-image: linear-gradient(
      45deg,
      ${({ theme }) =>
        `${theme.primaryForegroundColor}, ${theme.lightBlue}, ${theme.primaryForegroundColor} `}
    );
  }

  &:after {
    ${GRADIENT}
    bottom: 0;
    background-image: linear-gradient(
      45deg,
      ${({ theme }) => `${theme.lightBlue}, ${theme.primaryForegroundColor}, ${theme.lightBlue}`}
    );
  }
`

export function getBannerColors(
  type?: BannerMessageType['type'],
): {
  backgroundThemeColor: keyof any
  foregroundThemeColor: keyof any
} {
  switch (type) {
    case BannerTypes.SHARK:
      return {
        backgroundThemeColor: 'shark',
        foregroundThemeColor: 'primaryColor',
      }
    default:
      return {
        backgroundThemeColor: 'primaryColor',
        foregroundThemeColor: 'backgroundColor',
      }
  }
}

import { useEffect, useState } from 'react'

import { NextRouter, useRouter } from 'next/router'

export function useLocale(): Pick<NextRouter, 'locale'> & {
  isDefaultLocale: boolean
  defaultLocale: string
} {
  const { locale, defaultLocale } = useRouter()

  const [isDefaultLocale, setIsDefaultLocale] = useState(() => locale === defaultLocale)

  useEffect(() => {
    setIsDefaultLocale(locale === defaultLocale)
  }, [locale])

  return { isDefaultLocale, defaultLocale, locale }
}

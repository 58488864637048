import React from 'react'

import classnames from 'classnames'

import { TextDocument } from './TextDocument'
import { PrimaryTitle } from './title'

export interface TextBlockProps {
  title: string
  description: any
  alignment?: boolean
}

export const TextBlock = ({ title, description, alignment = false }: TextBlockProps) => (
  <div>
    <div className="max-w-screen-lg m-auto">
      <div className="flex flex-col px-3 lg:px-0">
        {title && <PrimaryTitle text={title} className="text-center pb-3" />}
        {description && (
          <TextDocument
            text={description.json}
            className={classnames({
              'text-center': !alignment,
              'text-left': alignment,
            })}
          />
        )}
      </div>
    </div>
  </div>
)

import { rgba } from 'polished'
import styled, { css } from 'styled-components'

import { Link } from '../atoms/Link'

export const BannerTitle = styled.h1`
  width: 100%;
  margin: 0;
  font-size: 32px;
  color: ${({ theme }) => theme.primaryColor};

  ${
    null
    // MQTablet
  } {
    text-align: left;
  }

  ${
    null
    // MQFullHd
  } {
    font-size: 44px;
  }
`

export const BannerDescription = styled.p`
  font-size: 20px;
  font-weight: 300;
  text-align: center;
  margin: 18px 0px;
  font-weight: normal;
  text-align: center;

  color: ${({ theme }) => theme.secondaryColor};

  ${
    null
    // MQTablet
  } {
    text-align: left;
  }

  b {
    color: ${({ theme }) => theme.primaryColor};
  }
`

export const BannerContainer = styled.div<Pick<any, 'background'>>`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;

  padding: 20px 10px;
  margin-bottom: 10px;

  ${
    null
    // MQTablet
  } {
    align-items: flex-start;
    padding: 0 6vw;
    min-height: 390px;

    &:before {
      z-index: -1;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      min-height: 390px;

      ${({ background, theme }) =>
        !!background &&
        typeof background === 'string' &&
        css`
          background-size: cover;
          background-repeat: no-repeat;
          background-position: left top;
        `};
    }
  }

  ${
    null
    // MQTHullHd
  } {
    padding: 0 90px;
  }
`

export const BannerImage = styled.img`
  display: none;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;

  ${
    null
    // MQFullHd
  } {
    &:before {
      display: block;
    }
  }

  ${
    null
    // MQMobile Large
  } {
    display: none;
  }
`

export const BannerLink = styled(Link)`
  padding: 10px 0px;
  margin: 10px 0px;
  width: 100%;
  text-align: center;
  border: solid 1px white;
  border-radius: 27px;
  color: ${({ theme }) => theme.secondaryColor};

  &:link,
  &:hover,
  &:visited,
  &:active {
    color: ${({ theme }) => theme.secondaryColor};
  }

  ${
    null
    // MQTablet
  } {
    text-align: left;
    border: none;
    margin: 0;

    &:link,
    &:hover,
    &:visited,
    &:active {
      text-decoration: underline;
    }
  }
`

export const BannerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 90px;
`

export const Gradient = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background: linear-gradient(
    to left,
    ${({ theme }) => rgba(theme.backgroundColor, 0)} 0%,
    ${({ theme }) => rgba(theme.backgroundColor, 0)} 40%,
    ${({ theme }) => theme.backgroundColor} 60%
  );
`

interface StatisticBlockProps {
  statBlockCollection: StatisticProps
}
interface StatisticProps {
  items: StatisticItemsProps[]
}

interface StatisticItemsProps {
  title: string
  subtitle: string
}

export const Block = ({ title, subtitle }: StatisticItemsProps) => {
  return (
    <div className="flex flex-col items-center justify-center w-72 md:w-60 min-h-100 md:min-h-150 rounded-lg bg-palombe p-3 md:p-5 mb-3 md:mb-5 md:mr-5 text-center">
      <h3>{title}</h3>
      <p>{subtitle}</p>
    </div>
  )
}

export const StatisticBlockCms = ({ statBlockCollection }) => {
  return (
    <div>
      <div>
        <div className="flex flex-col items-center md:flex-row md:flex-wrap md:justify-center">
          {statBlockCollection.items.map((stat, key) => (
            <Block title={stat.title} subtitle={stat.subtitle} key={`block_${key}`} />
          ))}
        </div>
      </div>
    </div>
  )
}

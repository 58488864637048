import styled from 'styled-components'

// import { Button, ButtonProps } from '../../atoms/button/Button'

interface SubmitButtonProps {}

export const SubmitButtonStyled = styled.button`
  margin: auto;
`

export const SubmitButton = ({ onClick, title, disabled = false, ...props }: any) => (
  <SubmitButtonStyled
    onClick={onClick}
    title={title}
    disabled={disabled}
    type="submit"
    {...props}
  />
)

import React from 'react'

import { Field, Form as FormikForm, Formik, FormikConfig, FormikProps, FormikValues } from 'formik'
import styled from 'styled-components'

import { SubmitButtonStyled } from './SubmitButton'

export type { FormikValues as Values }

export interface FormComponentProps extends FormikProps<any> {}

export interface FormProps extends FormikConfig<FormikValues> {
  children?: (formProps: FormikProps<FormikValues>) => React.ReactNode
  initialValues: object
  onSubmit: (values: FormikValues) => void
  validationSchema?: object
  onChange?: (values: FormikValues) => void
}

export { Field }

export const StyledFormContent = styled.div`
  width: 100%;
`

export const StyledForm = styled(FormikForm)`
  width: 100%;

  h1 {
    margin-top: 0;
    font-size: 50px;
  }

  ${SubmitButtonStyled} {
    margin-top: 40px;
  }

  ${
    null
    // MQMobileLarge
  } {
    text-align: center;
    justify-content: center;
    align-items: center;
  }
`

export const FormContent = StyledFormContent

export const Form = ({
  children,
  initialValues,
  onSubmit,
  validationSchema,
  ...props
}: FormProps) => (
  <Formik
    initialValues={initialValues}
    onSubmit={onSubmit}
    validationSchema={validationSchema}
    {...props}
  >
    {formProps => children && <StyledForm {...(props as any)}>{children(formProps)}</StyledForm>}
  </Formik>
)

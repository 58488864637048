import moment from 'moment'
import axios, { AxiosRequestConfig } from 'axios'
import jwt_decode, { JwtPayload } from 'jwt-decode'
import createAuthRefreshInterceptor from 'axios-auth-refresh'

import { config, deleteSession, retrieveSession, setSession, StorageKeys } from 'src'

const apiVersion = config.API_VERSION
const appVersion = `landing/${config.version}`
const endpoint = config.API_URL

export const requestClient = axios.create({
  withCredentials: true,
  baseURL: endpoint,
  headers: {
    Accept: `application/com.majelan.${apiVersion}`,
    'Content-Type': 'application/json',
    'majelan-app-version': appVersion,
  },
})

export const refreshClient = axios.create({
  withCredentials: true,
  baseURL: endpoint,
  headers: {
    Accept: `application/com.majelan.${apiVersion}`,
    'Content-Type': 'application/json',
    'majelan-app-version': appVersion,
  },
})

let isRefreshing = false

const refreshAuthLogic = async () => {
  try {
    if (isRefreshing) Promise.resolve()

    isRefreshing = true

    const session = retrieveSession(StorageKeys.AUTH)

    if (!session) Promise.reject()

    const { data } = await refreshClient.request({
      method: 'POST',
      url: '/token/refresh',
      data: { refreshToken: session?.refreshToken || '' },
    })

    const expirationDate = data.accessToken
      ? jwt_decode<JwtPayload>(data.accessToken || session.accessToken).exp || 0
      : session.expirationDate

    const newUser = { ...session, ...data, expirationDate }
    setSession(StorageKeys.AUTH, { ...newUser })

    isRefreshing = false
    Promise.resolve()
  } catch (error) {
    isRefreshing = false

    if (error.response) {
      deleteSession(StorageKeys.AUTH)
      window.location.assign(`${process.env.NEXT_PUBLIC_MAJELAN_URL}/auth/signin`)
    }
    Promise.resolve()
  }
}

createAuthRefreshInterceptor(requestClient, refreshAuthLogic, {
  pauseInstanceWhileRefreshing: true,
})

requestClient.interceptors.request.use(
  config => {
    if (isRefreshing) return

    isRefreshing = true

    const session = retrieveSession(StorageKeys.AUTH)

    return refreshClient
      .request({
        method: 'POST',
        url: '/token/refresh',
        data: { refreshToken: session?.refreshToken || '' },
      })
      .then(({ data }) => {
        const expirationDate = data.accessToken
          ? jwt_decode<JwtPayload>(data.accessToken || session.accessToken).exp || 0
          : session.expirationDate

        const newUser = { ...session, ...data, expirationDate }

        setSession(StorageKeys.AUTH, { ...newUser })
        isRefreshing = false

        return config
      })
      .catch(error => {
        isRefreshing = false

        if (error.response) {
          deleteSession(StorageKeys.AUTH)
          window.location.assign(`${process.env.NEXT_PUBLIC_MAJELAN_URL}/auth/signin`)
        }
      })
  },
  null,
  {
    synchronous: true,
    runWhen: config => {
      if (isRefreshing || config.url.includes('/token/refresh')) return false

      const auth = retrieveSession(StorageKeys.AUTH)

      if (!auth) return false

      const expire = moment.unix(auth.expirationDate)
      const now = moment.utc()

      return Boolean(expire.diff(now, 'minutes') <= 2 && auth.refreshToken)
    },
  },
)

requestClient.interceptors.request.use(config => {
  if (isRefreshing) return

  return config
})

export const requestQuery = (query: AxiosRequestConfig) =>
  requestClient.request(query).then(res => res.data)

export * from './auth'
export * from './user'
export * from './coupon'
export * from './partner'

export enum OAuthType {
  FACEBOOK = 'facebook',
  GOOGLE = 'google',
  APPLE = 'apple',
  MAIL = 'mail',
}

export interface OAuthLink {
  email: string
  lang?: string
}

type OAuthTypes = OAuthType

export interface OAuthMetadata {
  href?: string
  onPress?: (methodType: any) => Promise<void>
  source?: any
  label: {
    signin: string
    signup: string
  }
  backgroundColor: string
  color: string
}

export const OAuthList: { [key in OAuthTypes]: OAuthMetadata } = {
  [OAuthType.FACEBOOK]: {
    source: '/facebook.png',
    backgroundColor: '#0E74F6',
    label: {
      signin: 'signInScreen.button.facebook',
      signup: 'signUpScreen.button.facebook',
    },
    color: '#fff',
  },
  [OAuthType.GOOGLE]: {
    source: '/google.png',
    backgroundColor: '#fff',
    label: {
      signin: 'signInScreen.button.google',
      signup: 'signUpScreen.button.google',
    },
    color: '#2d2f3e',
  },
  [OAuthType.APPLE]: {
    source: '/apple.png',
    backgroundColor: '#fff',
    label: {
      signin: 'signInScreen.button.apple',
      signup: 'signUpScreen.button.apple',
    },
    color: '#2d2f3e',
  },
  [OAuthType.MAIL]: {
    source: '/mail.png',
    backgroundColor: '#fff',
    label: {
      signin: 'signInScreen.button.email',
      signup: 'signUpScreen.button.email',
    },
    color: '#383a4d',
  },
}

export interface AuthCommonData {
  platform: 'android' | 'web' | ''
  os: string
  deviceID: string
  lang: string
}

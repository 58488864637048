import { useIntl } from 'react-intl'

import { Field, FieldProps } from 'formik'
import styled from 'styled-components'

import { TEST_IDS } from '../../../utils/test-ids'
import { Input, InputProps, InputType } from '../../atoms/Input'

export interface FormLayout {
  direction?: any
}
export interface FieldInputProps extends Omit<InputProps, 'meta'>, FormLayout {
  label?: string
}

export const FormFieldInputWrapper = styled.div<FormLayout>`
  position: relative;
  width: 100%;

  justify-content: space-around;
  align-items: stretch;
  flex-direction: ${({ direction }) => direction};

  padding-bottom: 16px;
`

const FormFieldLabel = styled.span`
  flex: 0.3;
  padding-right: 10px;
  font-size: 12px;

  line-height: 43px;
  font-weight: bolder;

  color: ${({ theme }) => theme.quaternaryColor};
`

const FormError = styled.h2`
  position: absolute;
  bottom: -5px;
  left: 0;
  color: ${({ theme }) => theme.red};

  margin: 0;
`

export const FieldInput = ({
  displayCheck,
  displayError,
  editable,
  direction = 'horizontal',
  name,
  cancellable,
  label,
  ...props
}: FieldInputProps) => {
  const { formatMessage: getI18nKey } = useIntl()

  return (
    <FormFieldInputWrapper {...props} direction={direction}>
      {label && <FormFieldLabel>{label}</FormFieldLabel>}
      <Field name={name}>
        {({ field, form, meta }: FieldProps) => (
          <>
            {displayError && meta.error && (
              <FormError data-testid={TEST_IDS.COMPONENTS.FORM.INPUT.ERROR}>
                {getI18nKey({
                  id: meta.error,
                })}
              </FormError>
            )}
            <Input
              {...props}
              {...field}
              {...{ meta }}
              inputType={direction === 'horizontal' ? InputType.ROUNDED : InputType.MATERIAL}
              editable={editable}
              cancellable={cancellable}
              reset={() => {
                form.setFieldValue(name, '')
              }}
              name={name}
              displayCheck={displayCheck && !meta.error}
              displayError={displayError}
            />
          </>
        )}
      </Field>
    </FormFieldInputWrapper>
  )
}

import { AxiosError } from 'axios'
import { useRouter } from 'next/router'
import { useMutation, useQuery } from 'react-query'
import qs from 'query-string'
import { pick } from 'lodash'
import jwt_decode, { JwtPayload } from 'jwt-decode'
import { createContext, useContext, useState, PropsWithChildren } from 'react'

import { config, deleteSession, getQueryState, retrieveSession, setSession, StorageKeys } from 'src'
import { getUser, postLogout, postMagicLink, postSignin, postSignup, putUser } from 'src/resources'
import { NOTIFICATIONS, useNotification } from './NotificationProvider'
import { sendBatchEvent } from 'src/utils/analytics'

export const AuthContext = createContext<{
  user: any
  fetchUser: any
  signIn: any
  signUp: any
  requestPostMagicLink: any
  requestUpdateUser: any
  requestLogout: any
  setDataUser: any
  mutationUpdateUser: any
}>({
  user: null,
  fetchUser: () => {},
  signIn: () => {},
  signUp: () => {},
  requestPostMagicLink: () => {},
  requestUpdateUser: () => {},
  requestLogout: () => {},
  setDataUser: () => {},
  mutationUpdateUser: {},
})

export const AuthProvider = ({ children }: PropsWithChildren<any>) => {
  const router = useRouter()
  const { addNotification } = useNotification()

  const [user, setUser] = useState(() =>
    typeof window !== 'undefined' ? retrieveSession(StorageKeys.AUTH) : null,
  )

  const setDataUser = data => {
    setUser(() => {
      const session = retrieveSession(StorageKeys.AUTH)
      const expirationDate = data.accessToken
        ? jwt_decode<JwtPayload>(data.accessToken).exp || 0
        : session.expirationDate

      const newUser = { ...session, ...data, expirationDate }
      setSession(StorageKeys.AUTH, { ...newUser })

      return newUser
    })
  }

  const { refetch } = useQuery('user', () => getUser(user.UUID), {
    enabled: Boolean(user?.UUID || false),
    onSuccess: (dataUser: any) => {
      setDataUser(dataUser)
    },
    onError: () => {},
  })

  const fetchUser = () => {
    refetch()
  }

  const { mutate: signIn } = useMutation((data: any) => postSignin(data), {
    onSuccess: (data: any, variables: any) => {
      const { query, state } = getQueryState()

      if (data.Error) {
        if (data.Error === 'error user not found - you have to sign up first') {
          addNotification(NOTIFICATIONS.signInError)

          if (state?.sso) {
            router.push({
              pathname: state?.partnerServiceId
                ? '/auth/activation'
                : state?.type === 'signin'
                ? '/auth/signin'
                : '/auth/signup',
              query: qs.stringify(pick(state, ['code', 'partnerServiceId', 'continue'])),
            })
          }

          return
        }
        addNotification(NOTIFICATIONS.unknown)
        return
      }

      if (variables.accountType === 'link') {
        router.push(`/auth/magic?email=${encodeURIComponent(variables.accountDatas.email)}`)
      } else {
        setDataUser(data)

        addNotification({ ...NOTIFICATIONS.signin, values: { firstName: data.firstName } })

        router.push({
          pathname: state?.partnerServiceId
            ? config.PARTNER_BYTEL_AUTHORIZE
            : state?.code || query?.code
            ? '/app/activate'
            : query?.continue
            ? process.env.NEXT_PUBLIC_MAJELAN_URL
            : process.env.NEXT_PUBLIC_WEBAPP_URL,
          query: state?.partnerServiceId
            ? qs.stringify({
                response_type: 'code',
                client_id: config.PARTNER_BYTEL_CLIENT_ID,
                redirect_uri: `${config.APP_URL}/app/partner/activate?partnerServiceId=${router.query.partnerServiceId}`,
              })
            : qs.stringify(pick(state || query, ['code', 'partnerServiceId', 'continue'])),
        })
      }
    },
    onError: (error: AxiosError, variables: any) => {
      const { state } = getQueryState()
      if ([400, 401].includes(error.response.status) && variables.accountType === 'majelan') {
        addNotification(NOTIFICATIONS.signInErrorPassword)
        return
      }

      if (state?.sso) {
        router.push({
          pathname: state?.partnerServiceId
            ? '/auth/activation'
            : state.type === 'signin'
            ? '/auth/signin'
            : '/auth/signup',
          query: qs.stringify(pick(state, ['code', 'partnerServiceId', 'continue'])),
        })
      }

      addNotification(NOTIFICATIONS.unknown)
    },
  })

  const { mutate: signUp } = useMutation((data: any) => postSignup(data), {
    onSuccess: (data: any, variables: any) => {
      const { query, state } = getQueryState()

      if (data.Error) {
        if (data.Error === 'error: account already exists') {
          addNotification(NOTIFICATIONS.signUpError)

          if (state?.sso) {
            router.push({
              pathname: state?.partnerServiceId
                ? '/auth/activation'
                : state.type === 'signin'
                ? '/auth/signin'
                : '/auth/signup',
              query: qs.stringify(pick(state, ['code', 'partnerServiceId', 'continue'])),
            })
          }

          return
        }
        addNotification(NOTIFICATIONS.unknown)
        return
      }

      if (variables.accountType === 'link') {
        router.push(`/auth/magic?email=${encodeURIComponent(variables.accountDatas.email)}`)
      } else {
        setDataUser(data)

        sendBatchEvent(data.UUID, 'signup_signup_app', {
          method: data.signUpType,
          event_sender: 'website',
        })

        router.push({
          pathname: state?.partnerServiceId
            ? config.PARTNER_BYTEL_AUTHORIZE
            : router.query?.code
            ? '/app/activate'
            : router.query.continue
            ? process.env.NEXT_PUBLIC_MAJELAN_URL
            : process.env.NEXT_PUBLIC_WEBAPP_URL,
          query: state?.partnerServiceId
            ? qs.stringify({
                response_type: 'code',
                client_id: config.PARTNER_BYTEL_CLIENT_ID,
                redirect_uri: `${config.APP_URL}/app/partner/activate?partnerServiceId=${router.query.partnerServiceId}`,
              })
            : qs.stringify(pick(state || query, ['code', 'partnerServiceId', 'continue'])),
        })
      }
    },
    onError: () => {
      const { state } = getQueryState()
      if (state?.sso) {
        router.push({
          pathname: state.partnerServiceId
            ? '/auth/activation'
            : state.type === 'signin'
            ? '/auth/signin'
            : '/auth/signup',
          query: qs.stringify(pick(state, ['code', 'partnerServiceId', 'continue'])),
        })
      }
      addNotification(NOTIFICATIONS.unknown)
    },
  })

  const { mutate: magicLink, isLoading } = useMutation((data: any) => postMagicLink(data), {
    onSuccess: (data: any) => {
      setDataUser(data)

      router.push({
        pathname: !data.gcu
          ? '/app/terms'
          : router.query?.code
          ? '/app/activate'
          : process.env.NEXT_PUBLIC_WEBAPP_URL,
        query: router.query,
      })
    },
    onError: (error: AxiosError) => {
      if (error.response.status === 408) {
        addNotification(NOTIFICATIONS.signInMagicLinkError)
      }
      router.push('/auth/signin')
      addNotification(NOTIFICATIONS.unknown)
    },
  })

  const mutationUpdateUser = useMutation((data: any) => putUser(data), {
    onSuccess: (data: any) => {
      setDataUser(data)
    },
  })

  const { mutate: logout, isLoading: isLoadingLogout } = useMutation(() => postLogout(), {
    onSuccess: () => {
      setUser(null)
      deleteSession(StorageKeys.AUTH)
    },
  })

  const requestPostMagicLink = (data: any) => {
    if (isLoading) return

    magicLink(data)
  }

  const requestUpdateUser = (data: any) => {
    if (mutationUpdateUser.isLoading) return

    mutationUpdateUser.mutate({ ...user, ...data })
  }

  const requestLogout = () => {
    if (isLoadingLogout) return

    logout()
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        fetchUser,
        signIn,
        signUp,
        requestPostMagicLink,
        requestUpdateUser,
        requestLogout,
        setDataUser,
        mutationUpdateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)

import { AxiosAuthRefreshRequestConfig } from 'axios-auth-refresh'

import { requestQuery } from '.'

export enum AccountTypes {
  majelan = 'majelan',
  facebook = 'facebook',
  google = 'google',
  anonymous = 'anonymous',
  unknown = 'unknown',
  link = 'link',
  apple = 'apple',
}

export interface AuthRequest {
  accountType: AccountTypes
  accountDatas: {
    password?: string
    fbAccessToken?: string | undefined
    tokenID?: string
    email?: string
    lang?: string
    code?: string
    type?: string
  }
  lastName?: string
  firstName?: string
  newsletter?: boolean
  os?: string
  platform?: string
  lang?: string
}

export interface AuthRefreshToken {
  refreshToken: string
}

export interface AuthRequestMagicLink {
  email: string
  md5: string
  deviceID: string
}

export const postRefreshToken = (data: AuthRefreshToken | {} = {}) =>
  requestQuery({
    method: 'POST',
    url: '/token/refresh',
    data,
  })

export const postSignin = (data: AuthRequest) =>
  requestQuery({
    method: 'POST',
    url: '/users/login',
    data,
    // @ts-ignore
    skipAuthRefresh: true,
  })

export const postSignup = (data: AuthRequest) =>
  requestQuery({
    method: 'POST',
    url: '/users',
    data,
    // @ts-ignore
    skipAuthRefresh: true,
  })

export const postMagicLink = (data: AuthRequestMagicLink) =>
  requestQuery({
    method: 'POST',
    url: '/users/link',
    data,
  })

export const getCurrentAuth = () =>
  requestQuery({
    method: 'GET',
    url: '/me',
  } as AxiosAuthRefreshRequestConfig)

export const postLogout = () =>
  requestQuery({
    method: 'POST',
    url: '/users/logout',
  })

export interface ResetPasswordType {
  email: string
  resetHash: string
  password: string
}

export const postResetPassword = (data: ResetPasswordType) =>
  requestQuery({
    method: 'POST',
    url: '/users/password',
    data,
  })

export interface AskResetPasswordType {
  email: string
}

export const postAskResetPassword = (data: AskResetPasswordType) =>
  requestQuery({ method: 'POST', url: '/users/password/reset', data })

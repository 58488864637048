import React from 'react'

import { ImageCms } from './ImageCms'

export interface RatingProps {
  ratingData: any //RatingContentful
}

export const RatingCms = ({ ratingData: { label, image } }: RatingProps) => (
  <div className="flex flex-col items-center justify-center mt-10 md:mt-8 md:flex-row md:justify-evenly">
    <ImageCms
      className="w-20 md:mr-3"
      src={image.url}
      alt={image.title}
      width={image.width}
      height={image.height}
      contentType={image.contentType}
    />
    <p className="text-base mt-3 leading-4 text-cloud md:text-sm md:mt-0">{label}</p>
  </div>
)

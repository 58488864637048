import { Badge, BadgeSize, BadgeType } from '../atoms/Badge'

interface BadgeListProps {
  items?: BadgeType[]
  size?: BadgeSize
  onClick?: (item: BadgeType) => void
}

export const BadgeList = ({
  items = [BadgeType.IOS, BadgeType.ANDROID],
  size = BadgeSize.Medium,
  onClick,
  ...props
}: BadgeListProps) => (
  <div
    className="flex flex-col md:flex-row items-center justify-center space-y-10 md:space-y-0 md:space-x-10"
    {...props}
  >
    {items.map(item => (
      <Badge
        type={item}
        key={`badge_${item}`}
        size={size}
        onClick={() => onClick && onClick(item)}
      />
    ))}
  </div>
)

import { requestQuery } from '.'

export const getUser = UUID =>
  requestQuery({
    method: 'GET',
    url: `/users/${UUID}`,
  })

export const putUser = (data: any) =>
  requestQuery({ method: 'PUT', url: `/users/${data.UUID}`, data })

export const getSubscriptions = () =>
  requestQuery({
    method: 'GET',
    url: '/subscriptions',
  })

import React from 'react'
import classnames from 'classnames'
import { useRouter } from 'next/router'

import { Button } from '../atoms/button/Button'
import { CtaStripe, ImageCms, TextDocument } from '.'
import { computeAnalyticsTrackEvent } from 'src/utils/analytics'

export interface SharedSectionProps {
  title: string
  subtitle?: any
  description: any
  cta: any // CtaContentful | CtaStripeContentful
  image: any //AssetContentful
  displayType?: 'textRight' | 'textLeft'
  imageDisplayType?: 'crop' | 'full'
}

export const SharedSectionCms = ({
  title,
  subtitle,
  description,
  cta,
  image,
  displayType = 'textRight',
  imageDisplayType = 'crop',
}: SharedSectionProps) => {
  const { asPath } = useRouter()

  const renderCta = () => {
    switch (cta?.__typename) {
      case 'Cta':
        return (
          <Button
            className="mt-5 md:mt-0"
            title={cta.label}
            href={cta.url.includes('#') ? `${asPath.split('#')[0]}${cta.url}` : cta.url}
            buttonType={cta.displayType}
            onClick={() => {
              if (cta.analytics) {
                computeAnalyticsTrackEvent(cta.analytics.eventName, cta.analytics.categorie, {
                  name: cta.analytics.label,
                })
              }
            }}
            scroll={!cta.url.includes('#')}
          >
            {cta.label}
          </Button>
        )

      case 'CtaStripe':
        return (
          <CtaStripe
            label={cta.label}
            priceId={cta.priceId}
            promoCode={cta.promoCode}
            trialPeriod={cta.trialPeriod}
          />
        )

      default:
        return null
    }
  }

  return (
    <div>
      <div className="max-w-screen-lg m-auto">
        <div className="flex-row">
          {title && <h1 className="text-center">{title}</h1>}
          {subtitle && <TextDocument text={subtitle.json} className="text-center" />}
          <div
            className={classnames('flex flex-col mt-10 space-y-10 md:space-y-0', {
              'md:flex-row': displayType === 'textRight',
              'md:flex-row-reverse': displayType === 'textLeft',
            })}
          >
            <div className="flex md:w-2/5">
              <ImageCms
                src={image.url}
                className={classnames('flex place-self-center w-full', {
                  'h-full': imageDisplayType === 'crop',
                })}
                imageClassName={classnames(' md:rounded-3xl ', {
                  'object-cover': imageDisplayType === 'crop',
                })}
                width={image.width}
                height={image.height}
                contentType={image.contentType}
                sizes="(min-width: 768px) 50vw,
              100vw"
              />
            </div>
            <div
              className={classnames(
                'flex md:w-3/5 flex-col justify-center items-center px-4 md:px-0 md:items-start space-y-10',
                {
                  'md:ml-10 lg:ml-20 md:pr-3 lg:pr-0': displayType === 'textRight',
                  'md:mr-10 lg:mr-20 md:pl-3 lg:pl-0': displayType === 'textLeft',
                },
              )}
            >
              {description && (
                <TextDocument className="text-center md:text-left" text={description.json} />
              )}
              {cta && renderCta()}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

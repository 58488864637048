export enum StorageKeys {
  AUTH = '@majelan/landing',
  OFFER = '@majelan/landing@offer',
}

export const retrieveSession = (key: StorageKeys) => {
  const rawData = localStorage.getItem(key)

  return rawData && typeof rawData !== 'undefined' ? JSON.parse(rawData) : null
}

export const setSession = (key: StorageKeys, session: any) => {
  localStorage.setItem(key, JSON.stringify(session))
}

export const deleteSession = (key: StorageKeys) => localStorage.removeItem(key)

export const clearSession = (key: StorageKeys) => localStorage.removeItem(key)
